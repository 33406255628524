import React, { useState } from "react"
import Layout from "../components/layout"

import styles from "./faqs.module.css"

function FAQItem({ title, children }) {
    const [collapsed, setCollapsed] = useState(true);

    return(
        <div className={styles.faqItem}>
            <h3 onClick={() => { setCollapsed(!collapsed)}}>{title}</h3>
            <div className={[styles.faqItemBody, collapsed ? styles.collapsed : ''].join(' ')}>
                {children}
            </div>
        </div>
    )
}

export default function Faqs() {
    return(
        <Layout mainClass={styles.main}>
            <div className="wrapper">
                <h1>Frequently Asked Questions</h1>
                <div>
                    <FAQItem title="What is Vegly?">
                        <p>
                            Vegly is a meal planner for vegetarians. We create new, seasonal meal plans each week with breakfast, lunch, dinner and snacks for each day. You can use our plans and edit them to suit you using our database of over 700
                            vegetarian recipes. We also include nutritional information for each meal and day which is based on UK daily reference intakes. Our goal is to help make your life easier by making the inevitable daily tasks of doing your own
                            meal planning and grocery shopping easier, while giving you the peace of mind that you are in control of your nutrition.
                        </p>
                        <p>
                            For more information on nutrition guidelines and reference intakes there is a wealth of information available on the NHS website here:
                            <a href="http://www.nhs.uk/LiveWell/Goodfood/Pages/goodfoodhome.aspx" target="_blank" rel="external">http://www.nhs.uk/LiveWell/Goodfood/Pages/goodfoodhome.aspx</a>
                        </p>
                    </FAQItem>
                    <FAQItem title="Does Vegly offer vegan meal plans?">
                        <p>
                            While our meal plans are currently all vegetarian, they are customisable. While the meals available to free users are limited, our full database has over 700 recipes, with over 500 of those recipes being vegan or
                            vegan-friendly (i.e. using dairy substitutes).
                        </p>
                    </FAQItem>
                    <FAQItem title="Why are Vegly's day plans under 2000kcal?">
                        <p>
                            Our day plans are typically aimed to be anywhere between 1700 and 1850 calories as beverages will add calories on top of that. While drinking water is of course the healthiest option, we know that drinking nothing but water
                            24/7 is not easy to sustain. Vegly’s aim is to help people with meal planning, not to prescribe a strict diet. It is normal to have a cup of coffee in the morning and a tea in the afternoon, not to mention an occasional
                            glass of wine to compliment a good pasta. You don’t have to change your lifestyle to use our meal plans. However, if you have different caloric needs, our meal plans are customisable so you can add or remove meals and snacks
                            from your plan to suit your needs.
                        </p>
                    </FAQItem>
                    <FAQItem title="Who does Vegly cater for in the meal plans?">
                        <p>
                            We’ve based the meal plans on the daily reference intakes for the average adult aged 19-64, excluding those with special dietary requirements such as pregnancy. However, as our plans are customisable you should be able to
                            customise them to suit your needs. If you have any trouble with this, please do let us know.
                        </p>
                    </FAQItem>
                    <FAQItem title="Will I lose weight using Vegly?">
                        <p>
                            Vegly’s meal plans are not designed to be weight loss diet plans. However, you may experience weight loss using our plans if your diet was previously unbalanced. The 2000 calorie a day diet is only an average, and you should
                            be aware of your caloric intake needs, especially if you should be eating more. If your caloric needs are different, our plans are customisable to you can adjust the plans to suit your specific needs.
                        </p>
                    </FAQItem>
                    <FAQItem title="Should I still take vitamins or supplements?">
                        <p>
                            There is a lot of information out there on vitamins that you need being vegetarian. The main vitamin that must be supplemented is B12. There are also other vitamins that you should be aware that you may need such as iron and
                            Omega 3. While we do encourage the use of flaxseed oil (for Omega 3) in our salads and cold meals (as it cannot be heated), we do not have salads in every day plan. Also while we do include plenty of green leafy veg and
                            legumes for iron, these may not be at the maximum level required every day and you should be aware that you may need to supplement these.
                        </p>
                        <p>
                            Of course we could make sure you get everything with the meal plan, but we don’t really want to eat 500 grams of spinach or 600 grams of chickpeas every day, so we thought you might not want to either. For more information
                            about the vegetarian diet and nutrition have a look at the NHS website here
                            <a href="http://www.nhs.uk/Livewell/Vegetarianhealth/Pages/Vegetarianmealguide.aspx" target="_blank" rel="external">http://www.nhs.uk/Livewell/Vegetarianhealth/Pages/Vegetarianmealguide.aspx</a>, or ask your doctor if you
                            have any specific concerns.
                        </p>
                    </FAQItem>
                    <FAQItem title="What cooking equipment do I need?">
                        <p>
                            You will need the basic kitchen appliances and equipment. We avoid recipes that require unusual equipment such as deep fryers. If you do already have you may find a use for them in some of the recipes and are still welcome
                            to use them.
                        </p>
                        <p>
                            We consider basic appliances to be: stove, oven, microwave, kettle and toaster.<br />
                            Basic equipment includes the following: large non-stick frying pan, a good sharp knife, set of pots (at least one small and one large), chopping board, colander, grater, peeler, spatula, wooden spoon, ladle, tupperware,
                            mixing bowl, baking tray, baking paper, pie dish or individual oven dishes, kitchen towels and rolling pin. You may also find a hand blender, wok, small kitchen scale, measuring cups, a slotted spoon and a spaghetti spoon
                            helpful.
                        </p>
                    </FAQItem>
                    <FAQItem title="Will I have food waste?">
                        <p>
                            We do our best to limit any food waste that may occur in the plans we create, as we don’t want food waste ourselves. We try to ensure that our week plans have enough meals to use up what you may have leftover from buying a
                            perishable item, or we make sure it can get used up in the next week plan. We also include tips on how to deal with leftover ingredients in our recipes when possible. If you buy food in quantities matching the grocery list
                            then food waste should be minimal if there is any at all. As you can also customise the meal plans, you also have control over using ingredients that you may have on hand to avoid them going to waste.
                        </p>
                    </FAQItem>
                    <FAQItem title="Can I substitute cooking vegly meals by eating out instead?">
                        <p>
                            Yes, many meals (especially lunches and snacks) are available in restaurants and supermarkets. Always check first with the restaurant if their version of the meal is vegetarian especially where cheese or sauces are involved.
                            Some cheeses are not always vegetarian, such as parmesan, and some sauces may include fish sauce or a meat stock.
                        </p>
                    </FAQItem>
                    <FAQItem title="I don't like certain foods can I remove them from my meal plan?">
                        <p>Yes, you can customise your meal plan using our database of recipes.</p>
                    </FAQItem>
                    <FAQItem title="Is Vegly affiliated with any food products or brands?">
                        <p>
                            No, Vegly is does not have any affiliations. We aren’t affiliated with any of the websites or products that we may mention on our website or in the meal plans. You may have noticed that within the FAQ section we refer to the
                            NHS website, this is because they are the authority when it comes to nutrition in the UK and the information on their website is very helpful.
                        </p>
                        <p>
                            Sometimes in our recipes we reference specific vegetarian products, particularly the Quorn brand. This is because not only are their products widely available throughout the UK, but it also helps us to show you more accurate
                            nutrition information. Sometimes different brands of vegetarian products use different ingredients to make a similar product like a vegetarian “chicken”, so we rather refer to the specific product we used so you know how we
                            got to the nutrition information for that recipe.
                        </p>
                    </FAQItem>
                    <FAQItem title="What should I drink?">
                        <p>
                            What you drink is up to you. As Vegly is not a diet plan, we keep the calories under 2000kcal so that you can drink whatever you like to drink besides just water. If you are concerned about what to drink and your health, the
                            NHS website offers plenty of helpful advice here: <a href="http://www.nhs.uk/livewell/goodfood/pages/water-drinks.aspx" target="_blank" rel="external">http://www.nhs.uk/livewell/goodfood/pages/water-drinks.aspx</a> When
                            including sugary drinks especially, you should check the nutrition of that day plan to make sure you are not going over the daily reference intake of sugar.
                        </p>
                    </FAQItem>
                    <FAQItem title="What if I'm not good at cooking?">
                        <p>
                            Not everybody’s a master chef in the kitchen, and that’s okay. We keep our meals simple, with step by step instructions to help even beginners. Everybody should learn to cook, even just the basics. If the thought of cooking
                            still makes you uncomfortable try get a friend to help you. Also many of the meals can be commonly found already made in restaurants and supermarkets, so if something seems too complicated you can always look into the eat
                            out option.
                        </p>
                    </FAQItem>
                    <FAQItem title="I have a food allergy, can I still use Vegly?">
                        <p>
                            Currently we do not flag recipes that may have allergens, though we are looking to do this in future. However, our meals plans are customisable and you are in control of what food you buy when you go shopping. It is up to
                            you whether you want to try Vegly and see if it may work for you.
                        </p>
                    </FAQItem>
                    <FAQItem title="I have medical dietary requirements, can I still use Vegly?">
                        <p>You should speak to your doctor first before using Vegly.</p>
                    </FAQItem>
                </div>
            </div>
        </Layout>
    )
}